<template>
  <div class="programs">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "Programs",
}
</script>

<style scoped>

</style>
